import {isActive, getImportMap} from "./functions";
import singleSpa from "single-spa";
import {get} from "lodash";

const imports = process.env.IMPORTS;

singleSpa.registerApplication(
	"@KidsWay/login",
	() => System.import("@KidsWay/login"),
	() => isActive('login')
);
singleSpa.registerApplication(
	"@KidsWay/client",
	() => System.import("@KidsWay/client"),
	() => isActive('client')
);
singleSpa.registerApplication(
	"@KidsWay/contractor",
	() => System.import("@KidsWay/contractor"),
	() => isActive('contractor')
);
singleSpa.registerApplication(
	"@KidsWay/candidate",
	() => System.import("@KidsWay/candidate"),
	() => isActive('candidate')
);

window.addEventListener("unloadApp", (event) => {
	const app = `@KidsWay/${get(event, 'detail.app', '')}`;
	const type = get(event, 'detail.type', 'default');
	const getMountedApps = singleSpa.getMountedApps();

	if (!Array.isArray(getMountedApps) || !getMountedApps.some(appName => appName === app)) {
		return;
	}

	singleSpa.unloadApplication(app).then(() => {
		if (System.has(imports[app])) {
			System.delete(imports[app]);
		}

		if (type === 'reload') {
			//location.href = "/";
			history.pushState(null, '', '/');
		}
	});
})

singleSpa.start();
