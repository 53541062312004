export function getDataAccess() {
	return {
		role: localStorage.getItem("kidsway-lk-role") || undefined,
		token: localStorage.getItem("kidsway-lk-token") || undefined,
	};
}

export function getImportMap() {
	let imports = {};
	document.querySelectorAll('script[type="systemjs-importmap"]').forEach(maps => {
		const parseMaps = JSON.parse(maps.outerText);
		if (!parseMaps || !parseMaps.imports) {
			return;
		}

		imports = {...imports, ...parseMaps.imports};
	})

	return imports;
}

export function isActive(service) {
	const {role, token} = getDataAccess();

	if (role && token && !["Исполнитель", /*"Кандидат",*/ "Клиент"].some(item => item === role)) {
		
		localStorage.removeItem("kidsway-lk-role");
		localStorage.removeItem("kidsway-lk-token");
		location.href = "https://kidsway.city/index.php?option=com_kidsway&task=auth.load";
		return;
	}

	switch (service) {
		case 'login':
			return !role || !token;
		case 'contractor':
			return !!token && role === "Исполнитель";
		case 'candidate':
			return !!token && role === "Кандидат";
		case 'client':
			return !!token && role === "Клиент";
		default:
			return false;
	}
}
